import React from 'react'
import { Section, Container } from 'react-bulma-components'

// Components
import CaseStudyHero from 'components/case-study-hero'

function Clients() {
  return (
    <Section>
      <Container>
        <CaseStudyHero
          className="has-text-info"
          logo="https://www.datocms-assets.com/20955/1579120597-veezu-logo.png?bri=-60&h=25"
          mainImage="https://www.datocms-assets.com/20955/1692824271-veezu-mockup.png?w=400&fm=png"
          titleHtml="Zero to <span>six million mobile taxi bookings</span> a year?"
          to="/case-studies/veezu-taxi-booking-mobile-apps"
          excerpt="£80m in revenue. 81% automation. 170,000 monthly users."
          toText="Veezu Case Study"
        />
        <CaseStudyHero
          className="has-text-purple"
          flipped
          logo="https://www.datocms-assets.com/20955/1579120601-travelodge-logo.png?bri=-60&h=50"
          mainImage="https://www.datocms-assets.com/20955/1692829156-travelodge-mockup.png?w=400&fm=png"
          titleHtml="Prototypes to help hotel customers <span>travel light.</span>"
          excerpt="Virtual Reality. Mobile Wallet. Uber Integration."
          to="/case-studies/travelodge-prototypes"
          toText="Travelodge Case Study"
        />
      </Container>
    </Section>
  )
}

export default Clients
